<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="supplierId ? breadcrumbEdit : breadcrumb" :title="supplierId ? 'EditSupplier' : 'AddSupplier'">
    </breadcrumb-base>
    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="supplierId ? $t('EditSupplier') : $t('AddNewSupplier')" :subtitle="supplierId ? $t('SupplierEditTag') : $t('SupplierCreateTag')">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full">
                <div class="vx-row mb-2">
                  <div class="vx-col w-full mt-3">
                    <vs-input
                      v-validate="'required'"
                      validate-on="change"
                      name="full-name"
                      class="w-full" :label-placeholder="$t('FullName') + '*'"
                      v-model="fullName"/>
                    <span class="text-danger text-sm">{{ errors.first('full-name') }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      validate-on="change"
                      name="Company"
                      class="w-full" :label-placeholder="$t('Company')"
                      v-model="company"/>
                    <span class="text-danger text-sm">{{ errors.first('Company') }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      validate-on="change"
                      name="full-address"
                      class="w-full" :label-placeholder="$t('FullAddress')"
                      v-model="fullAddress"/>
                    <span class="text-danger text-sm">{{ errors.first('full-address') }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      v-validate="'required|email'"
                      validate-on="change"
                      name="email"
                      class="w-full" :label-placeholder="$t('EmailId') + '*'"
                      v-model="email"/>
                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                  </div>


                  <div class="vx-col w-full mt-3">
                    <vs-input
                      v-validate="'required|numeric'"
                      validate-on="change"

                      name="mobile"
                      class="w-full"
                      :label-placeholder="$t('Mobile') + '*'"
                      v-model="mobile"/>
                    <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      validate-on="change"
                      name="stnr"
                      class="w-full" :label-placeholder="$t('StNr')"
                      v-model="stNr"/>
                    <span class="text-danger text-sm">{{ errors.first('stnr') }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      v-validate="'numeric'"
                      validate-on="change"
                      name="telephone"
                      class="w-full" :label-placeholder="$t('Telephone')"
                      v-model="telephone"/>
                    <span class="text-danger text-sm">{{ errors.first('telephone') }}</span>
                  </div>

                </div>
              </div>

              <div class="vx-col md:w-1/2 w-full">
                <div class="vx-row mb-2">

                  <div class="vx-row vx-col w-full mt-3 ml-0 mr-0 flex-1" style="place-items: center;">
                    <vs-select autocomplete v-model="accountStatus" class="w-full select-large"
                               :label="$t('AccountStatus')">
                      <vs-select-item :key="index" :value="item.key" :text="item.title"
                                      v-for="(item,index) in accountStatusList" class="w-full"/>
                    </vs-select>
                    <span class="text-danger text-sm">{{ errors.first($t('AccountStatus')) }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      validate-on="change"
                      name="ustidnr"
                      class="w-full" :label-placeholder="$t('UstIdnr')"
                      v-model="ustidnr"/>
                    <span class="text-danger text-sm">{{ errors.first('ustidnr') }}</span>
                  </div>

                  <div class="vx-col w-full mt-3">
                    <vs-input
                      validate-on="change"
                      name="city"
                      class="w-full" :label-placeholder="$t('City')"
                      v-model="city"/>
                    <span class="text-danger text-sm">{{ errors.first('city') }}</span>
                  </div>


                  <div class="vx-col w-full mt-3">
                    <vs-input
                      validate-on="change"
                      name="country"
                      class="w-full" :label-placeholder="$t('Country')"
                      v-model="country"/>
                    <span class="text-danger text-sm">{{ errors.first('country') }}</span>
                  </div>


                  <div class="vx-col w-full mt-3">
                    <vs-input
                      v-validate="'required'"
                      validate-on="change"
                      name="postal-code"
                      type="number"
                      class="w-full" :label-placeholder="$t('PostalCode')"
                      v-model="postalCode"/>
                    <span class="text-danger text-sm">{{ errors.first('postal-code') }}</span>
                  </div>

                  <div class="vx-row w-full mb-6 mt-6 mx-1">
                    <div class="vx-col w-full mt-3">
                      <vs-textarea :label="$t('Remarks')"
                                   v-model="remarks"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mt-6">
              <vs-button class="ml-4" :disabled="!validateForm"
                         @click="addSupplierAndForward">{{ $t('SaveAndPublish') }}
              </vs-button>
            </div>
            <div class="m-4"></div>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'AddSupplier',
  components: {
    'v-select': vSelect,
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.fullName !== ''
    },
  },
  data() {
    return {
      company: '',
      remarks: '',
      postalCode: '',
      country: '',
      city: '',
      ustidnr: '',
      telephone: '',
      stNr: '',
      mobile: '',
      email: '',
      fullAddress: '',
      fullName: '',
      accountStatus: 'active',
      accountStatusList: [
        {
          title: 'Active',
          key: 'active',
        },
        {
          title: 'Inactive',
          key: 'inactive',
        },
      ],
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Suppliers', i18n: 'Suppliers', url: '/suppliers/view' },
        { title: 'Add Supplier', i18n: 'AddSupplier', active: true },
      ],
      breadcrumbEdit: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Suppliers', i18n: 'Suppliers', url: '/suppliers/view' },
        { title: 'Edit Supplier', i18n: 'EditSupplier', active: true },
      ],
    }
  },
  props: {
    supplierId: {
      type: Number | String,
      default: '',
    },
  },
  methods: {
    addSupplierAndForward() {
      if(isNaN(this.postalCode)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: "Please fill PostalCode",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      const payload = {
        remarks: this.remarks,
        postalCode: this.postalCode,
        country: this.country,
        company: this.company,
        city: this.city,
        ustidnr: this.ustidnr,
        telephone: this.telephone,
        stNr: this.stNr,
        mobile: this.mobile,
        email: this.email,
        fullAddress: this.fullAddress,
        fullName: this.fullName,
        status: this.accountStatus,
      }
      if (this.supplierId !== '') payload.supplier_id = this.supplierId
      this.$vs.loading()

      this.$store.dispatch('suppliers/addNewSupplier', payload).then((data) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.$router.push({ name: 'suppliers-view' })
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
  },
  mounted() {
    if (this.supplierId !== '') {
      this.$vs.loading()
      return this.$store.dispatch('suppliers/fetchSingleSupplier', this.supplierId)
        .then((data) => {
          this.remarks = data.remarks
          this.postalCode = data.postalcode
          this.country = data.country
          this.company = data.company
          this.city = data.city
          this.ustidnr = data.ustidnr
          this.telephone = data.telephone
          this.stNr = data.stnr
          this.mobile = data.mobile
          this.email = data.email
          this.fullAddress = data.address
          this.fullName = data.full_name
          this.accountStatus = data.status

          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
}
</script>

<style scoped>
  .c-center {
    margin: auto;
  }

  .p-type {
    padding: 0 1rem;
    font-size: 10px;
  }

  .p-type-select {
    padding: 0 0 0 1rem;
  }

  .upload-btn-wrapper {
    margin: 1rem;
    position: relative;
    display: flex;
  }

  .upload-contents {
    border: 1px dashed #00000040;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
    color: #2C2C2C;
    background-color: white;
    padding: 30px 30px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: auto;
  }

  .upload-btn-wrapper-denomination {
    position: absolute;
  }

  .upload-contents-denomination {
    padding: 5px;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
